import { TimeOfUseActionTypes } from './enums';
import { TimeOfUseAction, TimeOfUseState } from './types';

export const initialState: TimeOfUseState = {
    error: false,
    status: undefined,
    batteryOptimizationState: {
        locked: true,
        enabledOptimization: undefined,
    },
    batteryOptimizationDecision: {
        from: undefined,
        to: undefined,
        caseName: undefined,
        additionalInfo: {
            from: undefined,
            to: undefined,
            motives: [],
            price: {},
        },
    },
};

export default function reducer(
    state: TimeOfUseState = initialState,
    action: TimeOfUseAction,
): TimeOfUseState {
    switch (action.type) {
        case TimeOfUseActionTypes.TOU_STATUS:
            return {
                ...state,
                error: false,
                status: action.payload.status,
            };
        case TimeOfUseActionTypes.TOU_BATTERY_STATE:
            return {
                ...state,
                error: false,
                batteryOptimizationState:
                    action.payload.batteryOptimizationState ??
                    state.batteryOptimizationState,
            };
        case TimeOfUseActionTypes.TOU_BATTERY_DECISION:
            return {
                ...state,
                error: false,
                batteryOptimizationDecision:
                    action.payload.batteryOptimizationDecision,
            };
        case TimeOfUseActionTypes.TOU_BATTERY_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
}
