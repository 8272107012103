export enum TimeOfUseActionTypes {
    TOU_STATUS = 'TOU_STATUS',
    TOU_BATTERY_STATE = 'TOU_BATTERY_STATE',
    TOU_BATTERY_DECISION = 'TOU_BATTERY_DECISION',
    TOU_BATTERY_ERROR = 'TOU_BATTERY_ERROR',
}

export enum TimeOfUseStatus {
    ONLINE = 'ONLINE',
    SUBOPTIMAL = 'SUBOPTIMAL',
    OFFLINE = 'OFFLINE',
    DISABLED = 'DISABLED',
    INITIALIZING = 'INITIALIZING',
}
